import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Subscription from "../../components/subscription/Subscription";
import WhatAreYouWaiting from "../../components/home/WhatAreYouWaiting";
import { useEffect } from "react";

export default function PricePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <Box>
        <Subscription />
      </Box>
      <WhatAreYouWaiting />
    </Box>
  );
}
