import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useEffect } from "react";

function PrivacyPolicy() {
  return (
    <Container maxWidth="md">
      <Typography mb={2} variant="h3">
        Our Terms of Service
      </Typography>
      <Typography mb={2}>
        These Terms of Service and Privacy Policy describe how the Portamed
        Private Limited ("Portamed Pvt Ltd") mobile application ("Daily Doc
        Healthcare App", or "our App") for the health care system in which you
        have been authorized for its use ("Health Care System"), shall be used,
        and how selected information is handled within our App.
      </Typography>
      <Typography mb={2}>
        As a condition of using our App, you acknowledge and agree to the
        following Terms of Service:
      </Typography>
      <Typography mb={2}>
        1. Authorized Provider. You are an “Authorized Provider if you are a
        participating physician, nurse, clinician, therapist or other health
        care provider in the Health Care System in which you have been
        authorized to use our App and have been duly provisioned with a User ID
        and password permitting access to our App.
      </Typography>
      <Typography mb={2}>
        2. Compliance with Law. You have access to our App and its content only
        in accordance with all applicable laws, rules and regulations.
      </Typography>
      <Typography mb={2}>
        3. Appropriate use. Our App is a private service for Authorized
        Providers and it may not be accessed, nor may the content used, by
        patients or members of the general public. Only Authorized Providers are
        permitted to use our App and the content therein.
      </Typography>
      <Typography mb={2}>
        4. Security. Our App has reasonable security measures in place to help
        protect against the loss, misuse, or alteration of information under our
        control. These measures include encryption of personal information using
        the Secure Socket Layer (SSL) system during your log-in session, and
        using a secured messaging service when we send you personal information
        electronically. Despite these measures, the confidentiality of any
        communication or material transmitted to or from Authorized Providers
        within our App cannot be guaranteed. You should access our App only from
        a device that you know is secure and that has appropriate anti-malware
        software running.
      </Typography>
      <Typography mb={2}>
        5. Information available. Our App allows Authorized Providers to view
        treatment-related information about their patients and related aggregate
        information. All of this is deemed part of the content and constitutes
        confidential information.
      </Typography>
      <Typography mb={2}>
        6. User ID and password. The administrator of our App, which may also
        include the owner and provider of any related website, will, upon
        appropriate authentication and determination that you are a care
        provider authorized to use our App, issue you a User ID and password
        that is unique to you. You may never share your User ID and password
        with anybody else. A User ID and password permitting access to our App
        is a privilege that may be revoked at any time by the administrator of
        our App in his or her sole discretion.
      </Typography>
      <Typography mb={2}>
        7. Responsibility for User ID and password. You are solely responsible
        for any activities related to your User ID and password. You are
        required to immediately report to the administrator of our App any loss,
        disclosure or inappropriate access to your Daily Doc account, User ID or
        password, treatment-related information or patient-related file,
        reports, or other confidential content to which you or another person
        should not have access or when you suspect such or the likelihood of
        such.
      </Typography>
      <Typography mb={2} variant="h3">
        Privacy Policy
      </Typography>
      <Typography mb={2} variant="h4">
        Overview
      </Typography>
      <Typography mb={2}>
        Daily Doc Technologies LLC ("Portamed Pvt LTD") takes very seriously its
        obligation to protect the confidentiality of patients and providers
        personal information. Our App is intended to connect to servers and
        systems operated and maintained by the Health Care System in which you
        are an Authorized Provider, including but not limited to EPIC, Amion.com
        or other electronic health records, scheduling applications, paging and
        or messaging systems and the active directory of the Health Care System,
        in order to provide secure, mobile access to those applications, to
        patient health information, to other Authorized Providers within the
        Health Care System, and to providers competencies and availability.
      </Typography>
      <Typography mb={2}>
        This Privacy Policy describes how Portamed Pvt LTD and Daily Doc ("our
        App") uses, stores and transmits information and data within the Health
        Care System. By using our App to connect to other providers and the
        Health Care System, you consent to this Privacy Policy. Portamed Pvt LTD
        may modify this Privacy Policy at any time effective upon its posting.
        Your continued use of our App constitutes your acceptance of this
        Privacy Policy and any updates. Your use of our App is subject to the
        linked applications (such as EPIC, Cerner or other Electronic heath
        records, Amion.com, American messaging, and others) End User License
        Agreements.
      </Typography>
      <Typography mb={2} variant="h4">
        Personal Information
      </Typography>
      <Typography mb={2}>
        Portamed Pvt LTD does not sell or license any information that it may
        collect from you from using our App. Except for those items listed
        below, our App does not store any of your own or your patients personal
        information on your device. Portamed Pvt LTD attempts to minimize the
        amount of your and your patients personal or health information stored
        or retained on your device. Nevertheless, our App may:
      </Typography>
      <Typography mb={2}>
        Store patient personal health information entered into any messages sent
        through our App in your communications with other Authorized Providers.
        Store a copy of your profile, and your picture on your device if you
        choose to add a picture to your profile. Temporarily store your or your
        patients personal information in memory or on the device while you use
        our App.
      </Typography>
      <Typography mb={2}>
        Share some limited amount of information for purposes of troubleshooting
        and error correction directly or indirectly with Daily Doc with your
        consent.
      </Typography>
      <Typography mb={2}>
        In addition, in order to provide you certain features, our App may
        request information from servers and systems owned or operated by Daily
        Doc LLC and those servers and systems may record technical information
        about that request such as an IP address.
      </Typography>
      <Typography mb={2} variant="h4">
        Types of Data Collected
      </Typography>
      <Typography mb={2}>
        Among the types of data that our App collects, by itself or through the
        hospital system other connected applications, are cookies, usage data,
        and patients and other providers first and last names, phone numbers and
        email addresses. Personal data may be freely provided by you or other
        providers, or collected automatically when using our App.
      </Typography>
      <Typography mb={2}>
        Any use of cookies or of other tracking tools by our App or by the
        owners of third party applications connected to our App, unless stated
        otherwise, serves to identify providers and remember their preferences,
        for the sole purpose of providing the services provided by our App.
        Failure of Authorized Providers to provide certain personal data may
        make it impossible for our App to provide its services.
      </Typography>
      <Typography mb={2}>
        You are responsible for any personal data of third parties obtained,
        published or shared by you through our App, and by sharing such data you
        confirm that you have the affected third party's consent to provide the
        data through our App. Your Connections with the Health Care System
      </Typography>
      <Typography mb={2}>
        To use our App, you must have an account with the Health Care System and
        your use of our App is also subject to the Health Care System privacy
        policy. You understand that while connected, or attempting to connect,
        to the Health Care System, computer network, that network may collect,
        store, process, maintain, upload, sync, transmit, share, disclose and
        use certain data and related information, including but not limited to
        information or data regarding the characteristics or usage of your
        device, system and application software, and peripherals as well as your
        own and patients personal information and other content.
      </Typography>
      <Typography mb={2}>
        How We Protect Your Own and Your Patients Personal Information The
        security of your own and your patients‚ information and data while using
        our App is very important to us. Our App employs a variety of technical
        safeguards to protect the confidentiality, integrity, and availability
        of personal information including supporting Transport Layer Security
        (TLS)/Secure Sockets Layer (SSL) certificate technology and encryption.
      </Typography>
      <Typography mb={2}>
        In addition, the Health Care System with which you connect may use a
        variety of physical, administrative and technical measures to protect
        your personal information, including applicable policies relating to the
        handling of personal health information generally (such as encryption in
        transit or at rest, remote wiping, or other authorized or mandated
        measures).
      </Typography>
      <Typography mb={2} variant="h4">
        Retention time
      </Typography>
      <Typography mb={2}>
        Data collected by our App is kept for the time necessary to provide the
        services delivered by our App, and you can always request that Daily Doc
        suspend or remove any data. Portamed Pvt LTD will also retain
        information as necessary to comply with legal obligations, resolve
        disputes and enforce our agreements.
      </Typography>
      <Typography mb={2} variant="h4">
        Contact Information
      </Typography>
      <Typography mb={2}>
        To contact Portamed Private Limited with any questions or concern,
        please WhatsApp @ (+16124225444), email Portamed at
        portamedservices@gmail.com, or mail to Portamed Privated Limited,
        [A-103, Amit Bloomfield, Ambegaon BK, Near Pune Satara HGWY, Pune,
        Maharashtra, India 411046].
      </Typography>
    </Container>
  );
}

export default PrivacyPolicy;
