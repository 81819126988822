import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import EmailIcon from "@mui/icons-material/Email";
import api from "../../utilities/api";

import { styled } from "@mui/system";
import { showSnackbarAction } from "../../redux/alert/alertAction";

const StyledForm = styled("form")({
  //   display: 'flex',
  //   alignItems: 'center',
  width: "100%",
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const initialValues = {
  name: "",
  email: "",
  contactNo: "",
  company: "",
  message: "",
};

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
  contactNo: yup.string().required("Required"),
  company: yup.string().required("Required"),
});

export default function ContactForm() {
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  //   const onSubmit = async (values, actions) => {
  //     const formData = {
  //       feedBackDetails: {
  //         content: values.content,
  //         title: values.title,
  //       },
  //     };
  //     try {
  //       const url = `/hospitals/${hospital?._id}/dashboard/feedbacks`;
  //       const response = await api.post(url, formData);
  //       if (response) {
  //         dispatch(showSnackbarAction(response.data.message, "success"));
  //         actions.resetForm({ values: initialValues });
  //         actions.setStatus({ success: true });
  //       }
  //     } catch (err) {
  //       if (err) {
  //         actions.setStatus({ success: false });
  //         actions.setFieldError("content", err.response.data.message);
  //       }
  //     }
  //   };

  const onSubmit = async (values, actions) => {
    const formData = {
      contact: {
        name: values.name,
        email: values.email,
        mobile: values.contactNo,
        companyName: values.company,
        message: values.message,
      },
    };

    try {
      const url = "https://staging-api.dailydoc.io/api/query";
      const response = await api.post(url, formData);
      if (response) {
        actions.resetForm();
        // setmsg(response.data.message);
        // navigate('/message');
      }
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <Card sx={{ marginTop: 2, padding: 4 }}>
        <CardHeader
          title="Thank you for contacting Daily Doc"
          subheader="Please submit details using following form."
          sx={{ py: 3, px: 3 }}
        />
        <Box sx={{ marginBottom: 2, px: 3 }}>
          <StyledForm
            className="ward-form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              id="name"
              name="name"
              label="Name*"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              id="email"
              name="email"
              label="Email*"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              id="contactNo"
              name="contactNo"
              label="Contact No*"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              value={formik.values.contactNo}
              onChange={formik.handleChange}
              error={
                formik.touched.contactNo && Boolean(formik.errors.contactNo)
              }
              helperText={formik.touched.contactNo && formik.errors.contactNo}
            />
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              id="company"
              name="company"
              label="Company*"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              value={formik.values.company}
              onChange={formik.handleChange}
              error={formik.touched.company && Boolean(formik.errors.company)}
              helperText={formik.touched.company && formik.errors.company}
            />
            <TextField
              sx={{ marginBottom: 3 }}
              multiline
              rows={5}
              fullWidth
              id="message"
              name="message"
              label="Message*"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <Box>
              <Button
                type="submit"
                startIcon={<EmailIcon />}
                color="primary"
                variant="contained"
                disabled={formik.isSubmitting}
              >
                Send
              </Button>
            </Box>
          </StyledForm>
        </Box>
      </Card>
    </>
  );
}
