import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Box } from "@mui/system";

import SubscriptionCard from "./SubsciptionCard";
import { features, plans } from "./util";

const Subscription = () => {
  let activePlan;
  // make api call to get activePlan

  return (
    <Stack sx={{ textAlign: "center", marginTop: 4 }}>
      <Typography variant="h3">Upgrade today. Boost your service!</Typography>
      <Typography variant="body1" sx={{ color: "gray", marginTop: 1 }}>
        We are offering starter plan for free for limited time.
      </Typography>
      <Box sx={{ margin: 8, flexGrow: 1 }}>
        <Grid container spacing={{ xs: 5 }}>
          {plans.map((item, idx) => (
            <Grid item xs={12} md={6} key={idx}>
              <SubscriptionCard
                plan={item}
                activePlan={activePlan}
                features={features}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export default Subscription;
