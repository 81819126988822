import { useEffect, useMemo, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";

import EditHospital from "./EditHospital";
import DialogAlert from "../alerts/DialogAlert";
import Tooltip from "@mui/material/Tooltip";

import api from "../../utilities/api";
import { loadAllAdminsHospitalsAction } from "../../redux/admin-hospitals/hospitalsActions";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import EditNewHospital from "./EditNewHospital";

const HospitalTable = () => {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [isObDialogOpen, setisObDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [obHospital, setObHospital] = useState([]);

  const hospitals = useSelector((state) => state.adminHospitals?.allHospitals);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const getHospitals = async () => {
    try {
      const response = await api.get(
        `hospitals/primaryHospitals?userId=${user._id}`
      );

      dispatch(loadAllAdminsHospitalsAction(response?.data?.data));
    } catch (error) {
      dispatch(showSnackbarAction(error?.response?.data?.message, "error"));
    }
  };

  function editRow(id, row) {
    setRowToEdit(row);
    setisEditDialogOpen(true);
  }

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );

  // get onboarding hospital data and load in state
  const loadObHospital = async (id, row) => {
    try {
      const response = await api.get(`hospitals/${row?._id}?type=onboarding`);
      setObHospital(response.data.data);
      setisObDialogOpen(true);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setRowToDelete([]);
  };

  const closeObDialog = () => {
    setisObDialogOpen(false);
    setObHospital([]);
  };

  const deleteIncompleteHospital = async () => {
    if (rowToDelete?.completedStep && rowToDelete?.completedStep === 3) {
      closeDeleteDialog();
      setRowToDelete([]);
      dispatch(
        showSnackbarAction(
          "All data will be lost. You can not delete an active hospital. Contact Daily Doc customer care.",
          "error"
        )
      );
      return;
    }
    try {
      const response = await api.delete(
        `hospitals/${rowToDelete?._id}/users/${user._id}/primaryHospitals`
      );
      dispatch(loadAllAdminsHospitalsAction(response.data.data.hospitalsList));
      dispatch(showSnackbarAction(response.data.message, "success"));
      closeDeleteDialog(true);
      setRowToDelete([]);
    } catch (err) {
      closeDeleteDialog();
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Hospital Name",
        minWidth: 180,
        type: "string",
        flex: 1,
      },
      {
        field: "shortName",
        headerName: "Short Name",
        minWidth: 80,
        type: "string",
        flex: 0.8,
      },
      {
        field: "city",
        headerName: "City",
        minWidth: 100,
        type: "string",
        flex: 1,
      },
      {
        field: "state",
        headerName: "State",
        minWidth: 100,
        type: "string",
        flex: 1,
      },
      {
        field: "country",
        headerName: "Country",
        minWidth: 100,
        type: "string",
        flex: 1,
      },
      {
        field: "completedAllSteps",
        headerName: "Registrated",
        description: "Hospital registration completed",
        minWidth: 100,
        type: "boolean",
        flex: 0.8,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        minWidth: 120,
        flex: 1,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit hospital details">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={() => {
                if (params.row.completedStep && params.row.completedStep < 3) {
                  loadObHospital(params.id, params.row);
                } else {
                  editRow(params.id, params.row);
                }
              }}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete incompletely registered hospital">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
          ];
        },
      },
    ],
    [editRow, openDeleteDialog]
  );

  useEffect(() => {
    getHospitals();
  }, []);

  return (
    <Paper sx={{ height: 600, width: "100%", marginTop: 2, padding: 3 }}>
      <DataGrid
        sx={{
          // mx: 5,
          border: 2,
          borderColor: "divider",
          "& .MuiDataGrid-cell": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        columns={columns}
        rows={hospitals}
      />
      {isEditDialogOpen && (
        <EditHospital
          isEditDialogOpen={isEditDialogOpen}
          closeEditDialog={closeEditDialog}
          rowToEdit={rowToEdit}
        />
      )}
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteIncompleteHospital}
        title={`Delete ${rowToDelete?.name}`}
        msg={`Are you sure you want to delete ${rowToDelete?.name}?`}
      />
      {isObDialogOpen && (
        <EditNewHospital
          open={isObDialogOpen}
          onClose={closeObDialog}
          obHospital={obHospital}
        />
      )}
    </Paper>
  );
};

export default HospitalTable;
