import Container from '@mui/material/Container';

import EditOnboarding from '../../components/edit-onboarding/EditOnboarding';

export default function EditHospitalOnboardingPage() {
  return (
    <Container maxWidth='lg'>
      <EditOnboarding />
    </Container>
  );
}
