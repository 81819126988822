import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Hospitalpage from "../onboarding/HospitalPage";
import Departmentspage from "../onboarding/DepartmentsPage";
import OnboardingSuccess from "../onboarding/OnboardingSuccess";

import api from "../../utilities/api";
import setAuthToken from "../../utilities/setAuthToken";
import { countryList } from "../../utilities/countryList";
import ExitDialog from "../onboarding/ExitDialog";

import {
  loadStateListAction,
  loadCityListAction,
  removeStateListAction,
  removeCityListAction,
} from "../../redux/list/listAction";

import {
  loadObUserAction,
  loadObHospitalAction,
  loadObuserProfileAction,
  resetOnboardingAction,
  loadObDepartmentsAction,
  loadObWardsAction,
} from "../../redux/onboarding/onboardingAction";
import { logoutAction } from "../../redux/auth/authAction";
import { CircularProgress } from "@mui/material";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import ObWardsPage from "../onboarding/ObWardsPage";
import ObBedsPage from "../onboarding/ObBedsPage";

const PaperElement = styled(Paper)(({ theme }) => ({
  width: 1000,
  padding: 50,
  ".stepper-form": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const hospitalpageValidation = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  hospitalName: yup.string().required("Required"),
  country: yup
    .object()
    .shape({
      label: yup.string("Enter Country").required("Required"),
    })
    .typeError("Choose a country"),

  state: yup
    .object()
    .shape({
      label: yup.string("Enter state").required("Required"),
    })
    .typeError("Choose a state"),

  city: yup
    .object()
    .shape({
      label: yup.string("Enter city").required("Required"),
    })
    .typeError("Choose a city"),
});

const departmentpageValidation = yup.object({
  departments: yup
    .array()
    .of(yup.object({ name: yup.string().required("Required") }))
    .min(1, "Choose at least one department")
    .compact(),
});

const wardValidation = yup.object().shape({
  wards: yup
    .array()
    .of(yup.object({ name: yup.string().required("Required") }))
    .min(1, "Add at least one ward from your hospital.")
    .compact(),
});

const bedValidation = yup.object().shape({
  beds: yup
    .array()
    .of(yup.object({ bedName: yup.string().required("Required") }))
    .min(1, "Add at least one bed from your hospital.")
    .compact(),
});

const validationSchema = [
  hospitalpageValidation,
  departmentpageValidation,
  wardValidation,
  bedValidation,
];

const steps = [
  "User & Hospital Details",
  "Choose Departments",
  "Add Wards",
  "Add Beds",
];

const headings = [
  "Login Details",
  "User & Hospital Details",
  "Choose Departments",
  "Add Wards",
  "Add Beds",
];

export default function EditOnboarding({ editRow }) {
  const [isLoading, setIsLoading] = useState(true);
  const [initialCountry, setInitialCountry] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);

  const [activeStep, setactiveStep] = useState(0);
  const [activeHeading, setactiveHeading] = useState(1);
  const isLastStep = activeStep === steps.length - 1;
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const onboarding = useSelector((state) => state.onboarding);
  const { obUser, obHospital, obUserProfile, obDepartments, obWards } =
    onboarding;
  const { isStateListLoading, isCityListLoading } = useSelector(
    (state) => state.list
  );
  const navigate = useNavigate();

  async function onSubmit(values, actions) {
    if (activeStep === 0) {
      const {
        firstName,
        lastName,
        hospitalName,
        hospitalShortName,
        country,
        state,
        city,
      } = values;
      const formData = {
        user: {
          _id: obUser?._id,
          firstName,
          lastName,
        },
        hospital: {
          _id: obHospital?._id || "",
          name: hospitalName,
          code: hospitalShortName,
          country: country.value,
          state: state.value,
          city: city.value,
        },
      };

      try {
        const response = await api.post("/onboarding/ob3", formData);
        if (response) {
          // load hospital in for in redux here
          setAuthToken(response.data.data.token);
          dispatch(loadObuserProfileAction(response.data.data.userProfile));
          dispatch(loadObUserAction(response.data.data.user));
          dispatch(loadObHospitalAction(response.data.data.hospital));
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
          actions.setTouched({});
          actions.setSubmitting(false);
        }
      } catch (err) {
        dispatch(showSnackbarAction(err.response.data.message, "error"));
      }
    }

    if (activeStep === 1) {
      let { departments } = values;
      const formData = {
        hospital: obHospital?._id,
        departments,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
      };
      try {
        const response = await api.post("/onboarding/ob4", formData);
        if (response) {
          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("departments", err.response?.data.message);
        }
      }
    }

    if (activeStep === 2) {
      let { wards } = values;
      const formData = {
        hospital: obHospital?._id,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        wards: wards,
      };

      try {
        const response = await api.post("/onboarding/ob5", formData);
        if (response) {
          actions.setFieldValue("wards", response.data.data.savedWards);

          // update beds arry
          if (values.beds?.length > 0) {
            const newBeds = values.beds?.map((item) => {
              const updatedBed = response.data.data.savedWards.find(
                (i2) => i2.uid === item.wardUid
              );
              return updatedBed
                ? {
                    ...item,
                    wardId: updatedBed?._id,
                    wardName: updatedBed.name,
                  }
                : item;
            });
            actions.setFieldValue("beds", newBeds);
          }

          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("wards", err.response?.data.message);
        }
      }
    }

    if (activeStep === 3) {
      let { beds } = values;
      const formData = {
        hospital: obHospital?._id,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        wardBeds: beds,
      };

      try {
        const response = await api.post("/onboarding/ob6", formData);
        if (response) {
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("beds", err.response?.data.message);
        }
      }
    }
  }
  // onSubmit ends

  function renderStepContent(step) {
    switch (step) {
      case 0:
        return <Hospitalpage formik={formik} />;
      case 1:
        return <Departmentspage formik={formik} />;
      case 2:
        return <ObWardsPage formik={formik} />;
      case 3:
        return <ObBedsPage formik={formik} />;
      default:
        return null;
    }
  }

  function handleBack(step, heading) {
    if (step > 0) {
      setactiveStep(step - 1);
      setactiveHeading(heading - 1);
    }
  }

  const initialValues = {
    firstName: obUser?.firstName || "",
    lastName: obUser?.lastName || "",
    hospitalName: obHospital?.name || "",
    hospitalShortName: obHospital?.shortName || "",
    country: initialCountry,
    state: initialState,
    city: initialCity,
    departments: obDepartments || [],
    ward: "",
    wards: obWards || [],
    bed: "",
    beds: [],
    selectedWard: null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[activeStep],
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handleExit = () => {
    formik.resetForm({
      values: initialValues,
    });
    dispatch(resetOnboardingAction());
    dispatch(logoutAction());
    setactiveStep(0);
    setactiveHeading(1);
    setOpen(false);
    navigate("/");
  };

  async function getOnboardingData() {
    try {
      const res = await api.get("/onboarding/ob/session");
      const { user, userProfile, hospital, departments, wards, completedStep } =
        res.data.data;
      setactiveHeading(completedStep - 1);
      setactiveStep(completedStep - 2);
      if (user) {
        dispatch(loadObUserAction(user));
      }
      let country;
      if (hospital) {
        dispatch(loadObHospitalAction(hospital));
        country = countryList.find((c) => c.value === hospital.country);
        setInitialCountry(country);
      }
      if (userProfile) {
        dispatch(loadObuserProfileAction(userProfile));
      }
      if (departments) {
        dispatch(loadObDepartmentsAction(departments));
      }
      if (wards) {
        dispatch(loadObWardsAction(wards));
      }
      loadStateList(country?.countryId, hospital);
      setIsLoading(false);
    } catch (err) {
      // call alert action here
      navigate("/");
    }
  }

  const loadStateList = async (countryId, hospital) => {
    try {
      const response = await api.get(`locations/states?countryId=${countryId}`);
      const state = response?.data?.data?.states?.find(
        (s) => s.value === hospital?.state
      );
      setInitialState(state);
      dispatch(loadStateListAction(response?.data?.data?.states));
      loadCityList(state?.stateId, hospital);
    } catch (err) {}
  };

  const loadCityList = async (stateId, hospital) => {
    try {
      const response = await api.get(`locations/cities?stateId=${stateId}`);
      setInitialCity(
        response?.data?.data?.cities?.find((c) => c.value === hospital?.city)
      );
      dispatch(loadCityListAction(response?.data?.data?.cities));
    } catch (err) {}
  };

  useEffect(() => {
    getOnboardingData();
    return () => {
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
      dispatch(resetOnboardingAction());
      dispatch(logoutAction());
    };
  }, []);

  return (
    <Box>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading || isStateListLoading || isCityListLoading ? (
          <CircularProgress />
        ) : activeStep === steps.length ? (
          <OnboardingSuccess />
        ) : (
          <>
            <Typography mb={3} variant="h4">
              Welcome to Daily Doc
            </Typography>
            <PaperElement elevation={1}>
              <Grid container>
                <Grid item xs={4}>
                  <Stepper activeStep={activeHeading} orientation="vertical">
                    {headings.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          optional={
                            index === 4 ? (
                              <Typography variant="caption">
                                Last step
                              </Typography>
                            ) : null
                          }
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item xs={8}>
                  <form
                    className="stepper-form"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                  >
                    <Box sx={{ marginBottom: 4 }}>
                      {renderStepContent(activeStep)}
                    </Box>
                    <Stack direction="row-reverse" spacing={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        // fullWidth
                        disabled={formik.isSubmitting}
                        type="submit"
                        endIcon={<NavigateNextIcon />}
                      >
                        {isLastStep ? "Submit" : "Next"}
                      </Button>
                      {activeStep > 0 && (
                        <Button
                          onClick={() => handleBack(activeStep, activeHeading)}
                          variant="outlined"
                          startIcon={<ArrowBackIcon />}
                        >
                          Back
                        </Button>
                      )}

                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={openDialog}
                      >
                        Exit
                      </Button>
                    </Stack>
                  </form>
                </Grid>
              </Grid>
            </PaperElement>
          </>
        )}
      </Box>
      <ExitDialog
        handleExit={handleExit}
        open={open}
        closeDialog={closeDialog}
      />
    </Box>
  );
}
