// import { createStore, applyMiddleware } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// import { composeWithDevTools } from 'redux-devtools-extension';
// import thunk from 'redux-thunk';
// import rootReducer from './rootReducer';
// import setAuthToken from '../utilities/setAuthToken';

// const persistConfig = {
//   key: 'root',
//   storage,
// };
// const middleware = [thunk];

// const pReducer = persistReducer(persistConfig, rootReducer);

// // if (process.env.NODE_ENV === "development") {
// //   middlewares.push(logger);
// // }

// export const store = createStore(
//   pReducer,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// export const persistor = persistStore(store);

// // set up a store subscription listener*****
// //to store the users token in localStorage****

// //initialize current state from redux store for subscription comparison****
// //preventing undefined error*****
// let currentState = store.getState();

// store.subscribe(() => {
//   // keep track of the previous and current state to compare changes*****
//   let previousState = currentState;
//   currentState = store.getState();
//   // if the token changes set the value in localStorage and axios headers*******
//   if (previousState.auth.token !== currentState.auth.token) {
//     const token = currentState.auth.token;
//     setAuthToken(token);
//   }
// });

// export default store;

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  initMessageListener,
} from 'redux-state-sync';
import rootReducer from './rootReducer'; // Your root reducer file
import { composeWithDevTools } from 'redux-devtools-extension';
import setAuthToken from '../utilities/setAuthToken';

// Redux-Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ["profile", "conference", "userLocation"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux-State-Sync configuration
const stateSyncConfig = {
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
};
const stateSyncMiddleware = createStateSyncMiddleware(stateSyncConfig);

const middlewares = [thunk, stateSyncMiddleware];

let store;

if (process.env.NODE_ENV === 'development') {
  store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
} else {
  store = createStore(persistedReducer, applyMiddleware(...middlewares));
}

// const store = createStore(persistedReducer, applyMiddleware(...middlewares));

// Set up the message listener for state synchronization
initMessageListener(store);
// Initialize state with previous tab's state (optional)
initStateWithPrevTab(store);

// // set up a store subscription listener*****
// // to store the users token in localStorage****
// // initialize current state from redux store for subscription comparison****
// // preventing undefined error*****
let currentState = store.getState();
store.subscribe(() => {
  // keep track of the previous and current state to compare changes*****
  let previousState = currentState;
  currentState = store.getState();
  // if the token changes set the value in localStorage and axios headers*******
  if (previousState.auth.token !== currentState.auth.token) {
    const token = currentState.auth.token;
    setAuthToken(token);
  }
});

const persistor = persistStore(store);
export { store, persistor };
