import AddAdmin from "../../components/add-admin/AddAdmin";
function AddAdminPage() {
  return (
    <>
      <AddAdmin />
    </>
  );
}

export default AddAdminPage;
