import Box from "@mui/material/Box";
import ManageSubscription from "../../components/subscription/ManageSubscription";

export default function ManageSubscriptionPage() {
  return (
    <Box sx={{ width: "100%" }}>
      <ManageSubscription />
    </Box>
  );
}
