import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import api from '../../utilities/api';
import { getAccessControlData } from './utilAccessControl';
import { showSnackbarAction } from '../../redux/alert/alertAction';

const StyledForm = styled('form')({
  //   display: 'flex',
  //   alignItems: 'center',
  width: '100%',
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const initialValues = {
  employeeId: '',
  countryCode: '',
  mobile: '',
};

const validationSchema = yup.object().shape({
  countryCode: yup
    .string('Provide country code for mobile')
    .required('Required'),
  mobile: yup.string('Provide mobile number').required('Required'),
});

function AddMobileForm() {
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const { mobile, countryCode } = values;

    const formData = {
      users: [
        {
          mobile,
          countryCode,
        },
      ],
    };
    const url = `/hospitals/${hospital._id}/employees`;
    try {
      const response = await api.post(url, formData);
      if (response) {
        getAccessControlData(hospital._id);
        actions.resetForm({
          countryCode: '',
          mobile: '',
        });
        actions.setStatus({ success: true });
      }
      if (response.data.metadata.length > 0) {
        dispatch(
          showSnackbarAction(response.data.metadata[0].message, 'error')
        );
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError('mobile', err.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <StyledForm
      className="employee-form"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid size={4.5} xs={12}>
          <TextField
            sx={{ marginRight: 2 }}
            size="small"
            id="countryCode"
            name="countryCode"
            label="Country Code*"
            value={formik.values.countryCode}
            onChange={formik.handleChange}
            error={
              formik.touched.countryCode && Boolean(formik.errors.countryCode)
            }
            helperText={formik.touched.countryCode && formik.errors.countryCode}
          />
        </Grid>
        <Grid size={4.5} xs={12}>
          <TextField
            sx={{ marginRight: 2 }}
            size="small"
            id="mobile"
            name="mobile"
            label="Mobile*"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
          />
        </Grid>
        <Grid size={3} xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting}
            >
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </StyledForm>
  );
}

export default AddMobileForm;
