import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

import InstructionItem from "./InstructionItem";
import { welcomeStepsData } from "./stepsUtil";
import { Typography } from "@mui/material";

export default function GettingStarted() {
  return (
    <Card sx={{ my: 2 }}>
      <CardHeader
        avatar={<DirectionsRunIcon />}
        title="Get started. Onetime setup!!."
        // subheader="*Add departments, wards and beds before giving mobile app access to users!!"
      />
      <CardContent>
        <Typography
          sx={{
            mb: 2,
          }}
        >
          *First add departments, wards and beds available in your hospital
          before anyone using Daily Doc app!!
        </Typography>
        <List sx={{ maxWidth: 600 }}>
          {welcomeStepsData.map((step, indx) => (
            <InstructionItem
              key={step.title}
              number={indx + 1}
              title={step.title}
              subtitle={step.subtitle}
              steps={step.steps}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
