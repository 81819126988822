import Container from '@mui/material/Container';
import HospitalOnboarding from '../../components/onboarding/HospitalOnboarding';

function HospitalOnboardingPage() {
  return (
    <Container>
      <HospitalOnboarding />
    </Container>
  );
}

export default HospitalOnboardingPage;
