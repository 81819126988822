import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Gif } from "@mui/icons-material";
import FeatureItem from "./FeatureItem";
import { features } from "../subscription/util";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function AllFeatures() {
  return (
    <Box>
      <Typography variant="h2" sx={{ textAlign: "center", marginBottom: 5 }}>
        More Features
      </Typography>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 5,
          borderRadius: 5,
        }}
      >
        <Grid
          container
          columnSpacing={2}
          rowSpacing={0.5}
          sx={{
            // border: "1px solid black",
            width: 800,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {features?.map((feature) => (
            <Grid size={6} key={feature?.feature}>
              <FeatureItem feature={feature} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
}
