import { useMemo, useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";

import { getWardsData } from "./utilWards";
import api from "../../utilities/api";
import DialogAlert from "../alerts/DialogAlert";
import EditWard from "./EditWard";
import Tooltip from "@mui/material/Tooltip";

const validation = yup.object({
  ward: yup.string().required("Required"),
});

export default function WardsTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [deleteErr, setdeleteErr] = useState("");

  const hospital = useSelector((state) => state.auth.hospital);
  const { wards, isLoading } = useSelector((state) => state.wards);

  useEffect(() => {
    getWardsData(hospital._id);
    return () => {};
  }, [hospital]);

  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );

  // close dialoge
  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setdeleteErr("");
    // setRowToDelete([]);
  };

  const onSubmit = async (values, actions) => {
    try {
      const formData = {
        ward: {
          name: values.ward,
        },
      };

      const url = `/hospitals/${hospital._id}/wards/${rowToEdit?._id}`;
      const response = await api.patch(url, formData);
      if (response) {
        getWardsData(hospital._id);
        setisEditDialogOpen(false);
      }
    } catch (err) {
      if (err) actions.setFieldError("ward", err.response?.data.message);
    }
  };

  const deleteWard = async () => {
    const url = `/hospitals/${hospital._id}/wards`;
    try {
      const formData = {
        wards: [rowToDelete._id],
        hospital: hospital._id,
      };
      const response = await api.delete(url, { data: formData });
      if (response) {
        getWardsData(hospital._id);
        setisDeleteDialogOpen(false);
      }
    } catch (err) {
      if (err) formik.actions.setFieldError("ward", err.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      ward: rowToEdit.name || "",
    },
    validationSchema: validation,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Ward",
        description: "Ward",
        type: "string",
        minWidth: 150,
        flex: 1,
      },

      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 120,
        flex: 1,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete Ward">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit Ward">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [openDeleteDialog, editRow]
  );
  return (
    <Paper
      sx={{
        height: 600,
        width: "100%",
        marginTop: 2,
        padding: 4,
        // boxShadow: 8,
      }}
    >
      <DataGrid
        sx={{
          border: 2,
          borderColor: "divider",
          "& .MuiDataGrid-cell": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        columns={columns}
        rows={wards}
        loading={isLoading}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "linear-progress",
          },
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <EditWard
        open={isEditDialogOpen}
        formik={formik}
        onClose={closeEditDialog}
      />
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteWard}
        title={`Delete: ${rowToDelete?.name}`}
        msg={
          deleteErr || `Are you sure you want to delete ${rowToDelete?.name}?`
        }
      />
    </Paper>
  );
}
