import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddMobileForm from "./AddMobileForm";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Upload as UploadIcon } from "../icons/Upload";

export const AccessControlToolbar = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, marginTop: 2 }}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} md={3}>
          <Card
            sx={{
              minWidth: 160,
              textAlign: "center",
            }}
          >
            <CardHeader
              title="Bulk Upload"
              subheader="Add multiple users"
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2 }}>
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                onClick={() => {
                  navigate("/dashboard/upload-ac-csv");
                }}
              >
                Upload CSV
              </Button>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm>
          <Card>
            <CardHeader
              title="Single Upload"
              subheader="Add one user at a time. Add country code withouth + sign, ex: 91 . Add mobile number without any spaces or dashes or special characters. "
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, px: 2 }}>
              <AddMobileForm />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccessControlToolbar;
