import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";
import { getAccessControlData } from "./utilAccessControl";
import Paper from "@mui/material/Paper";
import DialogAlert from "../alerts/DialogAlert";
import Tooltip from "@mui/material/Tooltip";
import api from "../../utilities/api";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import { loadAccessControlDataAction } from "../../redux/acess-control/AccessControlAction";
import { PauseCircleFilledOutlined } from "@mui/icons-material";
import EditEmployeeForm from "../employees/EditEmployeeForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  px: 4,
  py: 8,
};

const StyledForm = styled("form")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  // '.MuiTextField-root': { marginTop: '0' },
  // '.MuiButton-root': { float: 'right' },
  ".MuiAutocomplete-root": { width: 200, marginRight: 16 },
  ".MuiTextField-root": { marginTop: 0, marginBottom: 0 },
});

//  for formik
const validationSchema = yup.object().shape({
  countryCode: yup
    .string("Provide country code for mobile")
    .required("Required"),
  mobile: yup.string("Provide mobile number").required("Required"),
  // firstName: yup.string('Provide Name').when('$isEditing', {
  //   is: true,
  //   then: (schema) => schema.required('Required'),
  //   otherwise: (schema) => schema,
  // }),
  firstName: yup.string().when("registered", {
    is: true,
    then: yup.string().required("Required"),
  }),
  lastName: yup.string("Provide Last Name").when("registered", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema,
  }),
  inputRole: yup.string("Provide Role").when("registered", {
    is: true,
    then: (schema) => schema.required("Required"),
  }),
  inputDepartment: yup.string("Provide Department").when("registered", {
    is: true,
    then: (schema) => schema.required("Required"),
  }),
  inputDesignation: yup.string("Provide Designation").when("registered", {
    is: true,
    then: (schema) => schema.required("Required"),
  }),
});

function AccessControlTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isUserDetailEditDialogOpen, setisUserDetailEditDialogOpen] =
    useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSuspendDialogOpen, setIsSuspendDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);

  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [rowToSuspend, setRowToSuspend] = useState([]);
  const [rowToActivate, setRowToActivate] = useState([]);

  const hospital = useSelector((state) => state.auth.hospital);
  const { accessControlData, isLoading } = useSelector(
    (state) => state.accessControl
  );
  const { defaultRoles, hospitalDepartments, defaultDesignations } =
    useSelector((state) => state.employees);

  const dispatch = useDispatch();

  // for formik set up
  const onFormSubmit = async (values, actions) => {
    let formData = {
      user: {
        mobile: values.mobile,
        countryCode: values.countryCode,
      },
    };

    let userFormData = {
      user: {
        firstName: values.firstName,
        lastName: values.lastName,
        hospitalRole: values.role._id,
        hospitalDesignation: values.designation._id,
        department: values.department._id,
      },
    };
    try {
      const response = await api.patch(
        `hospitals/${hospital?._id}/users/${rowToEdit?._id}`,
        isUserDetailEditDialogOpen ? userFormData : formData
      );
      dispatch(
        loadAccessControlDataAction(response.data.data.accessControlUsers)
      );
      closeEditDialog();
      closeUserEditDialog();
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };
  const formik = useFormik({
    initialValues: {
      employeeId: rowToEdit.id || "",
      countryCode: rowToEdit.countryCode || "",
      mobile: rowToEdit.mobile || "",
      // employeeId: rowToEdit.id || "",
      firstName: rowToEdit.firstName || "",
      lastName: rowToEdit.lastName || "",
      role:
        defaultRoles.find((e) => e.name === rowToEdit?.hospitalRole) ||
        defaultRoles[0],
      department:
        hospitalDepartments.find((e) => e.name === rowToEdit?.department) ||
        hospitalDepartments[0],
      designation:
        defaultDesignations.find((e) => e.name === rowToEdit?.designation) ||
        defaultDesignations[0],
      inputRole: rowToEdit.role || "",
      inputDepartment: rowToEdit.department || "",
      inputDesignation: rowToEdit.designation || "",
      registered: rowToEdit?.registered || false,
    },
    validationSchema: validationSchema,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
  });

  // user managment
  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      if (!row?.registered) {
        setisEditDialogOpen(true);
      } else {
        setisUserDetailEditDialogOpen(true);
      }
    },
    []
  );

  const suspendUser = async () => {
    const formData = {
      hospitalId: hospital?._id,
      userId: rowToSuspend?._id,
    };
    try {
      const response = await api.post("bd/user/suspend?type=USERS", formData);
      dispatch(loadAccessControlDataAction(response?.data?.data?.allUsers));
      closeSuspendDialog();
      dispatch(showSnackbarAction(response?.data?.message, "success"));
    } catch (err) {
      closeSuspendDialog();
      dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
    }
  };

  const activateUser = async () => {
    const formData = {
      hospitalId: hospital?._id,
      userId: rowToActivate?._id,
    };
    try {
      const response = await api.post("bd/user/activate?type=USERS", formData);
      dispatch(loadAccessControlDataAction(response?.data?.data?.allUsers));
      closeActivateDialog();
      dispatch(showSnackbarAction(response?.data?.message, "success"));
    } catch (err) {
      closeActivateDialog();
      dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
    }
  };

  const deleteUser = async () => {
    try {
      const response = await api.delete(
        `bd/user/${rowToDelete?._id}/hospitals/${hospital?._id}?type=USERS`
      );
      dispatch(loadAccessControlDataAction(response?.data?.data?.allUsers));
      closeDeleteDialog();
      dispatch(showSnackbarAction(response?.data?.message, "success"));
    } catch (err) {
      closeDeleteDialog();
      dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
    }
  };

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setIsDeleteDialogOpen(true);
    },
    []
  );

  // open suspend or active dialogalert
  const openSuspendActivateDialog = useCallback(
    (id, row) => () => {
      if (row?.suspended) {
        setRowToActivate(row);
        setIsActivateDialogOpen(true);
      } else {
        setRowToSuspend(row);
        setIsSuspendDialogOpen(true);
      }
    },
    []
  );

  // close dialoges
  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeUserEditDialog = () => {
    setisUserDetailEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setRowToDelete([]);
  };
  const closeSuspendDialog = () => {
    setIsSuspendDialogOpen(false);
    setRowToSuspend([]);
  };
  const closeActivateDialog = () => {
    setIsActivateDialogOpen(false);
    setRowToActivate([]);
  };

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "DD-ID",
        description: "Daily Doc ID",
        type: "string",
        minWidth: 60,
        flex: 1,
      },
      {
        field: "countryCode",
        headerName: "Code",
        description: "Country Code for mobiles",
        type: "string",
        minWidth: 60,
        flex: 1,
      },
      {
        field: "mobile",
        headerName: "Mobile",
        description: "Employee mobile number",
        type: "string",
        minWidth: 110,
        flex: 1,
      },
      {
        field: "registered",
        headerName: "Registered",
        description: "Employee has onboarded in the Daily Doc app",
        type: "boolean",
        minWidth: 60,
      },
      {
        field: "fullName",
        headerName: "Full Name",
        description: 'Employee"s Full Name',
        type: "string",
        minWidth: 150,
        flex: 1,
      },

      {
        field: "hospitalRole",
        headerName: "Role",
        description: "Role",
        type: "string",
        minWidth: 120,
        flex: 1,
      },
      {
        field: "department",
        headerName: "Department",
        description: "Department",
        type: "string",
        minWidth: 120,
        flex: 1,
      },
      {
        field: "designation",
        headerName: "Designation",
        description: "Designation",
        type: "string",
        minWidth: 110,
        flex: 1,
      },
      {
        field: "suspended",
        headerName: "Status",
        description: "Suspended",
        type: "boolean",
        minWidth: 100,
        flex: 1,
        renderCell: (data) => {
          return data.row.suspended ? (
            <span style={{ color: "red" }}>Suspended</span>
          ) : (
            <span>Active</span>
          );
        },
      },

      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 120,
        flex: 1,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete mobile">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit mobile">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
            <GridActionsCellItem
              icon={
                params?.row?.suspended ? (
                  <Tooltip title="Activate user">
                    <AddCircleOutlineIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Suspend user">
                    <PauseCircleFilledOutlined />
                  </Tooltip>
                )
              }
              label="Suspend"
              onClick={openSuspendActivateDialog(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [openDeleteDialog, openSuspendActivateDialog, editRow]
  );

  useEffect(() => {
    getAccessControlData(hospital?._id);
    return () => {};
  }, [hospital]);

  return (
    <Paper
      sx={{
        height: 600,
        width: "100%",
        marginTop: 2,
        // boxShadow: 8,
        padding: 4,
      }}
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        columns={columns}
        rows={accessControlData}
        sx={{
          border: 2,
          borderColor: "divider",
          "& .MuiDataGrid-cell": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        loading={isLoading}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "linear-progress",
          },
        }}
        componentsProps={{
          toolbar: {
            sx: {
              ".MuiGridToolbarQuickFilter-root .MuiSvgIcon-root": {
                color: "blue", // Set the icon color of Quick Filter
              },
            },
          },
        }}
      />
      <Modal
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledForm className="employee-form" onSubmit={formik.handleSubmit}>
            <TextField
              sx={{ marginRight: 2 }}
              size="small"
              // fullWidth
              id="countryCode"
              name="countryCode"
              label="Country Code"
              value={formik.values.countryCode}
              onChange={formik.handleChange}
              error={
                formik.touched.countryCode && Boolean(formik.errors.countryCode)
              }
              helperText={
                formik.touched.countryCode && formik.errors.countryCode
              }
            />
            <TextField
              sx={{ marginRight: 2 }}
              size="small"
              // fullWidth
              id="mobile"
              name="mobile"
              label="Mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
            <Button
              type="submit"
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </StyledForm>
        </Box>
      </Modal>
      <EditEmployeeForm
        open={isUserDetailEditDialogOpen}
        formik={formik}
        onClose={closeUserEditDialog}
      />
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteUser}
        title={`Delete mobile ${rowToDelete?.mobile}`}
        msg={`Are you sure you want to delete mobile: ${rowToDelete?.mobile} ${
          rowToDelete?.fullName && ","
        } ${rowToDelete?.fullName && rowToDelete?.fullName}?`}
      />
      <DialogAlert
        open={isSuspendDialogOpen}
        onClose={closeSuspendDialog}
        handleYes={suspendUser}
        title={`Suspend mobile: ${rowToSuspend?.mobile}`}
        msg={`Are you sure you want to suspend user: ${rowToSuspend?.mobile} ${
          rowToSuspend?.fullName && ","
        } ${rowToSuspend?.fullName}`}
      />
      <DialogAlert
        open={isActivateDialogOpen}
        onClose={closeActivateDialog}
        handleYes={activateUser}
        title={`Reactivate mobile: ${rowToActivate?.mobile}`}
        msg={`Are you sure you want to reactive user: ${
          rowToActivate?.mobile
        } ${rowToActivate?.fullName && ","} ${rowToActivate?.fullName}`}
      />
    </Paper>
  );
}

export default AccessControlTable;
