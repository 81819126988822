import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import Typography from "@mui/material/Typography";
import WaveIcon from "../icons/WaveIcon";
import theme from "../../utilities/theme";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();
  const chipsInChip = (
    <Box>
      <span
        style={{
          marginRight: 4,
        }}
      >
        One app, Endless
      </span>
      <Chip
        avatar={
          <CelebrationOutlinedIcon
            sx={{
              "&.MuiChip-avatar": {
                color: (theme) => theme.palette.warning.light,
              },
            }}
          />
        }
        label="Possibilities"
        sx={{
          backgroundColor: "rgb(204, 229, 255)",
          height: "auto",
          "& .MuiChip-label": {
            marginLeft: 1,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 1,
            paddingRight: 1,
          },
        }}
      />
    </Box>
  );
  return (
    <Box>
      <Box>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Box>
            <Chip
              sx={{
                border: "1px solid rgb(226, 226, 229)",
                height: "auto",
                "& .MuiChip-label": {
                  padding: 0.5,
                },
              }}
              variant="outlined"
              label={chipsInChip}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="h2">Hospital Communication</Typography>
            <Typography variant="h2">Simplified!</Typography>
          </Box>
          <Box>
            <WaveIcon />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Typography>
          Daily Doc is a secure Messaging platform for doctors, nurses, & other
          helthcare providers.
        </Typography>
        <Typography>
          Daily Doc prevents too many phone calls and non stop interruptions in
          daily workflow.
        </Typography>
        <Typography>
          It's patient centric architecture decreases erros and imrpoves
          outcomes.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Chip
          label="SIGNUP FOR FREE"
          onClick={() => navigate("/onboarding")}
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 40,
            color: "#fff",
            // backgroundColor: "rgb(204, 229, 255)",
            backgroundColor: "primary.main",
            ":hover": {
              backgroundColor: theme.palette.primary.dark,
              color: "#fff",
              cursor: "pointer",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Paper
          sx={{
            height: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 4,
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "#fff",
          }}
        >
          video
        </Paper>
      </Box>
    </Box>
  );
}
