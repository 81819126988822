import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import HospitalDetailsPage from "./HospitalDetailsPage";
import ObWardsPage from "../onboarding/ObWardsPage";
import ObBedsPage from "../onboarding/ObBedsPage";
import api from "../../utilities/api";
import ExitDialog from "../onboarding/ExitDialog";

import {
  removeCountryListAction,
  removeStateListAction,
  removeCityListAction,
} from "../../redux/list/listAction";

import { showSnackbarAction } from "../../redux/alert/alertAction";

const PaperElement = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  width: 1000,
  padding: 50,
  ".stepper-form": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: 'red',
    justifyContent: "space-between",
  },
}));

const steps = ["Hospital Details", "Add Wards", "Add Beds"];
const headings = ["Hospital Details", "Add Wards", "Add Beds"];

const hospitalDetailsValidation = yup.object().shape({
  hospitalName: yup.string("Enter Hospital Name").required("Required"),
  hospitalShortform: yup
    .string("Enter Hospital Name Shortform")
    .required("Required"),
  country: yup
    .object()
    .shape({
      label: yup.string("Enter Country Name").required("Required"),
    })
    .typeError("Choose a country"),

  state: yup
    .object()
    .shape({
      label: yup.string("Enter state").required("Required"),
    })
    .typeError("Choose a state"),
  city: yup
    .object()
    .shape({
      label: yup.string("Enter City").required("Required"),
    })
    .typeError("Choose a city"),
  departments: yup
    .array()
    .of(yup.object({ name: yup.string().required("Required") }))
    .min(1, "Choose at least one department")
    .compact(),
});

const wardValidation = yup.object().shape({
  wards: yup
    .array()
    .of(yup.object({ name: yup.string().required("Required") }))
    .min(1, "Add at least one ward from your hospital.")
    .compact(),
});

const bedValidation = yup.object().shape({
  beds: yup
    .array()
    .of(yup.object({ bedName: yup.string().required("Required") }))
    .min(1, "Add at least one bed from your hospital.")
    .compact(),
});

const validationSchema = [
  hospitalDetailsValidation,
  wardValidation,
  bedValidation,
];

const AddNewHospital = () => {
  const [open, setOpen] = useState(false);

  //   const [defaultCountry, setdefaultCountry] = useState("IN");
  const [activeStep, setactiveStep] = useState(0);
  const [activeHeading, setactiveHeading] = useState(0);
  const [hospitalId, setHospitalId] = useState(null);
  const isLastStep = activeStep === steps.length - 1;

  //  pull redux state

  const hospital = useSelector((state) => state.auth.hospital);
  const user = useSelector((state) => state.auth.user);
  const defaultDepartments = useSelector(
    (state) => state.onboarding.defaultDepartments
  );

  // for calling action creator
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // function for going to the back page.
  function handleBack(step, heading) {
    if (step > 0 && heading > 0) {
      setactiveStep(step - 1);
      setactiveHeading(heading - 1);
    }
  }

  useEffect(() => {
    return () => setHospitalId(null);
  }, []);

  const onSubmit = async (values, actions) => {
    if (activeStep === 0) {
      const formData = {
        hospitalDetails: {
          userId: user?._id,
          loggedInHospitalId: hospital?._id,
          _id: hospitalId ? hospitalId : "",
          name: values.hospitalName,
          shortName: values.hospitalShortform,
          country: values.country.value,
          state: values.state.value,
          city: values.city.value,
          departments: values.departments,
        },
      };

      try {
        const response = await api.post(
          "/onboarding/hospitals/step1",
          formData
        );

        setHospitalId(response.data.data.savedHospital._id);
        dispatch(showSnackbarAction(response.data.message, "success"));
        actions.setTouched({});
        actions.setSubmitting(false);
        setactiveStep(activeStep + 1);
        setactiveHeading(activeHeading + 1);
      } catch (err) {
        dispatch(showSnackbarAction(err.response.data.message, "error"));
      }
    }
    if (activeStep === 1) {
      let { wards } = values;
      const formData = {
        userId: user?._id,
        loggedInHospitalId: hospital?._id,
        hospitalId: hospitalId,
        wards: wards,
      };

      try {
        const response = await api.post(
          "/onboarding/hospitals/step2",
          formData
        );

        if (response) {
          actions.setFieldValue("wards", response?.data?.data?.savedWards);

          // update beds arry
          if (values.beds?.length > 0) {
            const newBeds = values.beds?.map((item) => {
              const updatedBed = response.data.data.savedWards.find(
                (i2) => i2.uid === item.wardUid
              );
              return updatedBed
                ? {
                    ...item,
                    wardId: updatedBed?._id,
                    wardName: updatedBed.name,
                  }
                : item;
            });
            actions.setFieldValue("beds", newBeds);
          }
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
        }
      }
    }

    if (activeStep === 2) {
      let { beds } = values;
      const formData = {
        userId: user?._id,
        loggedInHospitalId: hospital?._id,
        hospitalId: hospitalId ? hospitalId : "",
        wardBeds: beds,
      };

      try {
        const response = await api.post(
          "/onboarding/hospitals/step3",
          formData
        );

        if (response) {
          navigate("/dashboard/hospitals");
        }
      } catch (err) {
        if (err) {
          dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
        }
      }
    }
  };

  // onSubmit ends

  const initialValues = {
    hospitalName: "",
    hospitalShortform: "",
    country: null,
    state: null,
    city: null,
    departments: [],
    ward: "",
    wards: [],
    selectedWard: null,
    bed: "",
    beds: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[activeStep],
    onSubmit: onSubmit,
  });

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handleExit = () => {
    formik.resetForm({
      values: initialValues,
    });
    // dispatch(resetOnboardingAction());
    // dispatch(logoutAction());
    setactiveStep(0);
    setactiveHeading(0);
    setOpen(false);
    navigate("/dashboard/hospitals");
  };

  // function to show part of the form based on the current step
  function renderStepContent(step) {
    switch (step) {
      case 0:
        return <HospitalDetailsPage formik={formik} />;
      case 1:
        return <ObWardsPage formik={formik} />;
      case 2:
        return <ObBedsPage formik={formik} />;

      default:
        return null;
    }
  }

  useEffect(() => {
    return () => {
      dispatch(removeCountryListAction());
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
      //   dispatch(resetOnboardingAction());
    };
  }, []);

  return (
    <Box>
      <Card
        sx={{
          minHeight: 600,
          marginTop: 2,
          marginBottom: 2,
          padding: 6,
          boxShadow: 8,
        }}
      >
        <CardHeader
          avatar={
            <AccountBalanceIcon sx={{ fontSize: 24 }} color="secondary" />
          }
          title="Steps to add a new hospital"
          sx={{ p: 1, m: 0 }}
        />
        <CardContent sx={{ p: 0, mt: 8, mx: 0 }}>
          <Box sx={{ maxWidth: 600, marginBottom: 8 }}>
            <Stepper activeStep={activeHeading} orientation="horizontal">
              {headings.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box sx={{ px: 1 }}>
            <form
              className="stepper-form"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <Box>{renderStepContent(activeStep)}</Box>
              <Stack sx={{ marginTop: 8 }} direction="row-reverse" spacing={2}>
                <Button
                  color="primary"
                  variant="contained"
                  // fullWidth
                  disabled={formik.isSubmitting}
                  type="submit"
                  endIcon={<NavigateNextIcon />}
                >
                  {isLastStep ? "Submit" : "Next"}
                </Button>
                {activeStep > 0 && (
                  <Button
                    onClick={() => handleBack(activeStep, activeHeading)}
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                  >
                    Back
                  </Button>
                )}
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={openDialog}
                >
                  Exit
                </Button>
              </Stack>
            </form>
          </Box>
        </CardContent>
      </Card>
      <ExitDialog
        handleExit={handleExit}
        open={open}
        closeDialog={closeDialog}
      />
    </Box>
  );
};

export default AddNewHospital;
