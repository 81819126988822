import Container from "@mui/material/Container";
import Subscription from "../../components/subscription/Subscription";

function SubscriptionPage() {
  return (
    <Container maxWidth="lg">
      <Subscription />
    </Container>
  );
}

export default SubscriptionPage;
