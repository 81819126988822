import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import CellTowerIcon from "@mui/icons-material/CellTower";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import HttpsIcon from "@mui/icons-material/Https";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 24,
  backgroundColor: "rgb(241, 244, 249)",
  //   ...theme.typography.body2,
  height: 400,
  maxWidth: "100%",
  borderRadius: 40,
  padding: theme.spacing(3),
  textAlign: "center",
}));

const features = [
  {
    icon: <HttpsIcon />,
    header: "End to End Encryption",
    text: "End to end encryption provides secure reliable messaging.",
  },
  {
    icon: <FolderSharedIcon />,
    header: "Hospital Directory",
    text: "Search through entire hospital directory and get hold of anyone working in the hospital instantly.",
  },
  {
    icon: <CellTowerIcon />,
    header: "Live Care Team",
    text: "Find doctors, nurses, pharmacist & other active providers arranged in patient care team. ",
  },
  {
    icon: <PhotoCameraIcon />,
    header: "Share Files and Images, Videos",
    text: "Share files, images, videos, audiofiles etc without issues.",
  },
  {
    icon: <TouchAppIcon />,
    header: "Rapid Onboarding",
    text: "Onboard the whole hospital staff in less than 10 minutes. Easy step by step guide.",
  },
  {
    icon: <AcUnitIcon />,
    header: "Retain and Retrive Data",
    text: "Retain data for years, review and download data when desired.",
  },
];

export default function Features() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          marginBottom: 4,
        }}
      >
        What Daily Doc offers
      </Typography>
      <Box
        sx={{
          marginBottom: 5,
        }}
      >
        <Box>
          <Grid container spacing={2}>
            {features.map((feature) => (
              <Grid key={feature.header} size={4}>
                <Item>
                  <Avatar
                    sx={{ bgcolor: "secondary.main", width: 60, height: 60 }}
                  >
                    {feature.icon}
                  </Avatar>
                  <Typography variant="h6">{feature.header}</Typography>
                  <Typography>{feature.text}</Typography>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
