import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import theme from "../../utilities/theme";
import DDLogo from "../icons/ddLogo";

const HomeAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.body2,
}));

const pages = ["Pricing", "About", "Contact"];
const menu = [
  {
    item: "Price",
    path: "/price",
  },
  {
    item: "About",
    path: "/about",
  },
  {
    item: "Contact us",
    path: "/contact",
  },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function MainAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <HomeAppBar position="static">
      <Toolbar
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
        disableGutters
      >
        <IconButton
          sx={{
            display: { xs: "none", md: "flex" },
            flexGrow: 0,
            ":hover": {
              cursor: "pointer",
            },
          }}
          disableRipple
          onClick={() => navigate("/")}
        >
          <DDLogo href="/" className="dd-logo" inheritViewBox />
        </IconButton>
        <Typography
          variant="h5"
          noWrap
          onClick={() => navigate("/")}
          sx={{
            flexGrow: 0,
            mr: 2,
            ml: 2,
            display: { xs: "none", md: "flex" },
            // fontFamily: "monospace",
            fontWeight: 700,
            // letterSpacing: ".3rem",
            color: "primary.main",
            textDecoration: "none",
            ":hover": {
              cursor: "pointer",
            },
          }}
        >
          Daily Doc
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography sx={{ textAlign: "center" }}>{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          LOGO
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              // border: "1px solid black",
              padding: "0px 100px",
              borderRadius: 40,
              backgroundColor: "rgb(235, 238, 243, 0.7)",
              // backgroundColor: "neutral.200",
            }}
            direction="row"
            spacing={2}
          >
            {menu.map((e) => (
              <Chip
                key={e.item}
                onClick={() => navigate(e.path)}
                label={e.item}
                sx={{
                  padding: 2.5,
                  borderRadius: 40,
                  backgroundColor: "inherit",
                  ":hover": {
                    cursor: "pointer",
                    backgroundColor: "neutral.200",
                  },
                }}
                // sx={{ my: 2, color: "white", display: "block" }}
              />
            ))}
          </Stack>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          {isAuthenticated && user ? (
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
          ) : (
            <Chip
              onClick={() => navigate("/login")}
              label="Login"
              sx={{
                paddingTop: 2.5,
                paddingBottom: 2.5,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 40,
                color: "#fff",
                backgroundColor: (theme) => theme.palette.primary.main,
                ":hover": {
                  backgroundColor: theme.palette.primary.dark,
                  color: "#fff",
                  cursor: "pointer",
                },
              }}
            />
          )}

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography sx={{ textAlign: "center" }}>{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </HomeAppBar>
  );
}
export default MainAppBar;
