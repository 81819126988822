import { useMemo, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import { getDepartmentsData } from "./utilDepartments";
import api from "../../utilities/api";
import DialogAlert from "../alerts/DialogAlert";
import EditDepartment from "./EditDepartment";
import Tooltip from "@mui/material/Tooltip";
import { showSnackbarAction } from "../../redux/alert/alertAction";

function DepartmentsTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [deleteErr, setdeleteErr] = useState("");

  const hospital = useSelector((state) => state.auth.hospital);
  const { departmentsList, isLoading } = useSelector(
    (state) => state.departments
  );
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    department: yup.string("Please choose a department").required("Required"),
  });

  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );
  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );
  // close dialoge
  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setdeleteErr("");
    // setRowToDelete([]);
  };

  const onSubmit = async (values, actions) => {
    try {
      const formData = {
        department: {
          name: values.department,
        },
      };
      const url = `/hospitals/${hospital._id}/departments/${rowToEdit?._id}`;
      // /hospitals/:hospitalId/departments/:departmentId
      const response = await api.patch(url, formData);
      if (response) {
        getDepartmentsData(hospital._id);
        setisEditDialogOpen(false);
      }
    } catch (err) {
      if (err) actions.setFieldError("department", err.response?.data.message);
    }
  };

  const deleteDepartment = async () => {
    const url = `/hospitals/${hospital._id}/departments`;
    try {
      const formData = {
        departments: [rowToDelete._id],
        hospital: hospital._id,
      };

      const response = await api.delete(url, { data: formData });
      if (response) {
        getDepartmentsData(hospital._id);
        setisDeleteDialogOpen(false);
      }
    } catch (err) {
      setisDeleteDialogOpen(false);
      // if (err) formik.setFieldError("department", err.response?.data.message);
      dispatch(showSnackbarAction(err.response.data.message, "error"));
      // dispatch snackbar
    }
  };

  const formik = useFormik({
    initialValues: {
      department: rowToEdit.name || "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    getDepartmentsData(hospital._id);
    return () => {};
  }, [hospital]);

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Department",
        description: "Department",
        type: "string",
        minWidth: 250,
        flex: 1,
        // editable: true,
      },

      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete Department">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit Department">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [editRow, openDeleteDialog]
  );

  return (
    <>
      <Paper
        sx={{
          height: 600,
          width: "100%",
          marginTop: 2,
          padding: 4,
          // boxShadow: 8,
        }}
      >
        <DataGrid
          sx={{
            border: 2,
            borderColor: "divider",
            "& .MuiDataGrid-cell": {
              borderBottom: 2,
              borderBottomColor: "divider",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: 2,
              borderBottomColor: "divider",
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          loading={isLoading}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "linear-progress",
            },
          }}
          columns={columns}
          rows={departmentsList}
        />
        <DialogAlert
          open={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          handleYes={deleteDepartment}
          title={`Delete: ${rowToDelete?.name}`}
          msg={
            deleteErr || `Are you sure you want to delete ${rowToDelete?.name}?`
          }
        />
        <EditDepartment
          open={isEditDialogOpen}
          formik={formik}
          onClose={closeEditDialog}
        />
      </Paper>
    </>
  );
}

export default DepartmentsTable;
