import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import SubSubMenu from "../../components/navbar/SubSubMenu";

export default function InstructionsLayout() {
  const manageInstructionsSubmenu = [
    { label: "Get Started", path: "/dashboard/instructions" },
    {
      label: "Add users to Daily Doc app",
      path: "/dashboard/instructions/add-users",
    },
  ];
  return (
    <Container sx={{ display: "flex" }} maxWidth="xl">
      <Box>
        <SubSubMenu menuList={manageInstructionsSubmenu} />
      </Box>
      <Box px={2} sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Container>
  );
}
