import { parsePhoneNumber } from "react-phone-number-input";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CardHeader from "@mui/material/CardHeader";

import { getDepartmentsData } from "../departments/utilDepartments";
import getGeoInfo from "../../utilities/getGeoInfo";
import api from "../../utilities/api";

import MobilePage from "../reusables/MobilePage";
import EmailRolePage from "./EmailRolePage";
import { showSnackbarAction } from "../../redux/alert/alertAction";

const StyledForm = styled("form")({
  width: "100%",
});

const pages = ["Enter Mobile Or Email", "Enter OTP"];

const initialValues = {
  phone: "",
  email: "",
  adminPanelRole: "",
  departments: [],
};

const mobileValidation = yup.object({
  phone: yup.string("Enter a mobile No").required("Required"),
});

const emailRoleValidation = yup.object({
  email: yup
    .string("Enter Email")
    .email("Enter a valid email")
    .required("Required"),
  adminPanelRole: yup.string().required("Required"),
  departments: yup.array().when("adminPanelRole", {
    is: (adminPanelRole) => adminPanelRole !== "613b5f785c768a51d90f295c",
    then: yup.array().min(1, "Choose at least one department").compact(),
  }),
});

const validationSchema = [mobileValidation, emailRoleValidation];

export default function AddAdmin() {
  const [currentStep, setCurrentStep] = useState(0);
  const [defaultCountry, setdefaultCountry] = useState("IN");
  const [adminPanelRoles, setadminPanelRoles] = useState([]);
  const [admin, setadmin] = useState({
    adminName: "",
    userPresent: false,
    registered: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hospital = useSelector((state) => state.auth.hospital);

  async function getAdminPanelRoles() {
    try {
      const response = await api.get("/acl/roles?webAccess=true");
      if (response) setadminPanelRoles(response?.data?.data);
    } catch (err) {
      dispatch(showSnackbarAction(err?.response?.data?.message, "error"));
    }
  }
  useEffect(() => {
    getGeoInfo(setdefaultCountry);
    getAdminPanelRoles();
    getDepartmentsData(hospital?._id);
  }, [hospital]);

  const isLastPage = currentStep === pages.length - 1;
  const backStep = (step) => {
    setCurrentStep(step - 1);
  };

  const onSubmit = async (values, actions) => {
    const { phone, email, adminPanelRole, departments } = values;
    const phoneNumber = parsePhoneNumber(phone);

    if (currentStep === 0) {
      const formData = {
        user: {
          countryCode: phoneNumber?.countryCallingCode,
          mobile: phoneNumber?.nationalNumber,
          rawMobile: phoneNumber?.number,
        },
      };
      const url = `/hospitals/${hospital._id}/access/step1`;
      try {
        const response = await api.post(url, formData);
        if (response) {
          const { user } = response?.data?.data;
          setadmin({
            adminName: user?.firstName,
            userPresent: user?.userPresent,
            registered: user?.registered,
          });
          actions.setTouched({});
          actions.setSubmitting(false);
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("phone", err.response?.data.message);
        }
      }
    }

    if (currentStep === 1) {
      const formData = {
        user: {
          mobile: phoneNumber?.nationalNumber,
          countryCode: phoneNumber?.countryCallingCode,
          email,
          aclRole: adminPanelRole,
          departments,
          userPresent: admin.userPresent,
          registered: admin.registered,
        },
      };
      const url = `/hospitals/${hospital._id}/access/step2`;

      try {
        const response = await api.post(url, formData);
        if (response) {
          formik.resetForm({
            values: initialValues,
          });
          setCurrentStep(0);
          navigate("/dashboard/admins");
        }
      } catch (err) {
        actions.setFieldError("email", err.response?.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[currentStep],
    onSubmit: onSubmit,
  });

  const resetMobile = () => {
    formik.resetForm({
      values: initialValues,
    });
    setCurrentStep(0);
  };

  function renderPageContent(step) {
    switch (step) {
      case 0:
        return <MobilePage formik={formik} defaultCountry={defaultCountry} />;
      case 1:
        return (
          <EmailRolePage
            adminName={admin.adminName}
            resetMobile={resetMobile}
            formik={formik}
            adminPanelRoles={adminPanelRoles}
          />
        );

      default:
        return <div>Page Not Found</div>;
    }
  }

  return (
    <Card
      sx={{
        marginTop: 2,
        maxWidth: 800,
      }}
    >
      <CardHeader
        avatar={
          <SupervisorAccountIcon sx={{ fontSize: 40 }} color="secondary" />
        }
        title="Add access to admin panel"
      />
      <CardContent>
        <StyledForm onSubmit={formik.handleSubmit} autoComplete="off">
          {renderPageContent(currentStep)}
          <Box
            sx={{
              display: "flex",
              marginTop: 6,
              marginBotton: 3,
              gap: 2,
              justifyContent: "flex-end",
            }}
          >
            {currentStep > 0 && (
              <Button
                variant="outlined"
                // fullWidth
                type="button"
                onClick={() => backStep(currentStep)}
                className="button button-green"
              >
                Back
              </Button>
            )}
            <Box sx={{ position: "relative" }}>
              <Button
                size="large"
                variant="contained"
                fullWidth
                type="submit"
                disabled={formik.isSubmitting}
              >
                {isLastPage ? "Submit" : "Next"}
              </Button>
              {formik.isSubmitting && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
          </Box>
        </StyledForm>
      </CardContent>
    </Card>
  );
}
