import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import DDLogo from "../icons/ddLogo";
import Box from "@mui/material/Box";

export default function Welcome() {
  const navigate = useNavigate();
  const steps = [
    { title: "Add departments", path: "/dashboard/departments" },
    { title: "Add Wards", path: "/dashboard/wards" },
    { title: "Add Beds", path: "/dashboard/beds" },
    {
      title: "Add user's mobile to User table",
      path: "/dashboard/access-control",
    },
    {
      title: "Register users on Daily Doc app",
      path: "/dashboard/instructions",
    },
  ];
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        background: "#448afc",
        color: "white",
        padding: 4,
        boxShadow: 8,
      }}
    >
      <CardContent>
        <Box mb={2}>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
            <DDLogo />
            <Typography ml={2} variant="h5">
              Daily Doc
            </Typography>
          </Box>
          <Typography mb={1} variant="body1">
            Leader in Hospital communication.
          </Typography>
        </Box>
        <Box>
          <Typography mb={1} variant="h3">
            Get started
          </Typography>
          <Typography mb={1} variant="body1">
            Five easy steps. Click on the link for each.
          </Typography>
          <Box mb={2}>
            {steps.map((step, indx) => (
              <Button
                onClick={() => navigate(`${step.path}`)}
                key={step.title}
                sx={{
                  fontSize: "1rem",
                  fontWeight: 400,
                  lineHeight: 1.5,
                  color: "#fff",
                  padding: 0,
                  marginRight: 1,
                  "&:hover": { color: "#111827" },
                }}
              >
                {indx + 1}. {step.title}
              </Button>
            ))}
          </Box>
          <Typography variant="body1" mb={2} sx={{ color: "neutral.900" }}>
            Review detailed instructions on how to use Daily Doc app by using
            link below.
          </Typography>
          <Button
            onClick={() => navigate("instructions")}
            variant="contained"
            sx={{ width: 250, height: 50 }}
          >
            Review steps to get started
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
