import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import SubSubMenu from "../../components/navbar/SubSubMenu";

export default function SubscriptionLayout() {
  const subscriptionSubmenu = [
    { label: "Pricing", path: "/dashboard/subscription" },
    {
      label: "Manage Subscription",
      path: "/dashboard/subscription/manage",
    },
  ];
  return (
    <Container sx={{ display: "flex" }} maxWidth="xl">
      <Box>
        <SubSubMenu menuList={subscriptionSubmenu} />
      </Box>
      <Box px={2} sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Container>
  );
}
