import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Typography } from "@mui/material";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import TransferAccount from "./TransferAccount";
import MyModal from "../my-modal/MyModal";
import SuspendAccount from "./SuspendAccount";
import DeleteAccount from "./DeleteAccount";
import api from "../../utilities/api";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import { progressBarStyle } from "../../utilities/commonUtil";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: "#fff",
  // ...theme.typography.body2,
  borderRadius: 8,
  padding: theme.spacing(4),
  // textAlign: "center",
  // color: theme.palette.text.secondary,
  // ...theme.applyStyles("dark", {
  //   backgroundColor: "#1A2027",
  // }),
}));

export default function ManageSubscription() {
  const [myHospital, setMyHospital] = useState(null);
  const [isMyHospitalLoading, setIsMyHospitalLoading] = useState(true);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const handleOpenTransferModal = () => setOpenTransferModal(true);
  const handleCloseTransferModal = () => setOpenTransferModal(false);

  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [isSuspendLoading, setIsSuspendLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const handleOpenSuspendModal = () => setOpenSuspendModal(true);
  const handleCloseSuspendModal = () => setOpenSuspendModal(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  const cancelAccountActivity = async (type) => {
    if (type === "TRANSFER") setIsTransferLoading(true);
    if (type === "SUSPEND") setIsSuspendLoading(true);
    if (type === "DELETE") setIsDeleteLoading(true);

    try {
      const response = await api.post(
        `users/hospitals/${hospital?._id}/account/cancelReq?type=${type}`
      );
      if (response) {
        setMyHospital(response?.data?.data?.hospital);
        dispatch(showSnackbarAction(response?.data?.message, "success"));
        if (type === "TRANSFER") setIsTransferLoading(false);
        if (type === "SUSPEND") setIsSuspendLoading(false);
        if (type === "DELETE") setIsDeleteLoading(false);
      }
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
      if (type === "TRANSFER") setIsTransferLoading(false);
      if (type === "SUSPEND") setIsSuspendLoading(false);
      if (type === "DELETE") setIsDeleteLoading(false);
    }
  };

  const getHospitalDetail = async (hospitalId) => {
    try {
      const response = await api.get(`hospitals/${hospitalId}`);
      if (response) {
        setMyHospital(response?.data?.data?.hospital);
        setIsMyHospitalLoading(false);
      }
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
      setIsMyHospitalLoading(false);
    }
  };

  useEffect(() => {
    getHospitalDetail(hospital?._id);
  }, [hospital?._id]);

  return (
    <>
      {isMyHospitalLoading ? (
        <Box>
          <CircularProgress sx={progressBarStyle} />
        </Box>
      ) : (
        <Box>
          <Stack spacing={2}>
            <Item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <TransferWithinAStationIcon />
                <h4>Transfer account</h4>
              </Box>
              <Typography
                sx={{
                  paddingBottom: 2,
                }}
              >
                Your subscription to Daily Doc can be transferred to someone
                else. The new person will be main admin for the hospital. Please
                provide name, email and mobie no with country code for new
                admin. Once the new admin registers and accepts terms and
                conditions, account will be transferred to his name.
              </Typography>

              {myHospital?.transferRequest ? (
                <Box>
                  <Typography
                    sx={{
                      paddingBottom: 1,
                    }}
                  >
                    Account Transfer Requested
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      border: 2,
                      borderColor: "divider",
                      padding: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Grid
                      // size={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Box>
                        <Typography>
                          {myHospital?.trasferUserFirstName}{" "}
                          {myHospital?.transferUserlastName}
                        </Typography>
                        <Typography>{myHospital?.transferEmail}</Typography>
                        <Typography>
                          {myHospital?.transferCountryCode}{" "}
                          {myHospital?.transferMobile}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Typography>Being processed</Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Box sx={{ position: "relative" }}>
                        <Button
                          disabled={isTransferLoading}
                          onClick={() => cancelAccountActivity("TRANSFER")}
                        >
                          Cancel account transfer
                        </Button>
                        {isTransferLoading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Button variant="outlined" onClick={handleOpenTransferModal}>
                  Initiate Account Transfer
                </Button>
              )}
            </Item>
            <Item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <HourglassTopIcon />
                <h4>Suspend account</h4>
              </Box>
              <Typography
                sx={{
                  paddingBottom: 2,
                }}
              >
                You may temporarilty pause the subscription for a limited time.
                We will maintain the data. You can restart it when you ready.
              </Typography>

              {myHospital?.suspendRequest ? (
                <Box>
                  <Typography
                    sx={{
                      paddingBottom: 1,
                    }}
                  >
                    Account Suspend Requested
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      border: 2,
                      borderColor: "divider",
                      padding: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Grid
                      // size={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Typography>Suspend request sent</Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Typography>Being processed</Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Box sx={{ position: "relative" }}>
                        <Button
                          disabled={isSuspendLoading}
                          onClick={() => cancelAccountActivity("SUSPEND")}
                        >
                          Cancel suspend request
                        </Button>
                        {isSuspendLoading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Button variant="outlined" onClick={handleOpenSuspendModal}>
                  Suspend account
                </Button>
              )}
            </Item>
            <Item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <CancelPresentationIcon />
                <h4>Cancel this hospital account</h4>
              </Box>
              <Typography
                sx={{
                  paddingBottom: 2,
                }}
              >
                Our team will contact you to cancel the account. Data retention
                policy applies. Only this hospital's subscription will be
                deleted. If there are multiple hospitals associated with your
                account, other hospital will not be deleted. Log in into each
                hospital account to cancel the subscription.
              </Typography>

              {myHospital?.deleteRequest ? (
                <Box>
                  <Typography
                    sx={{
                      paddingBottom: 1,
                    }}
                  >
                    Account Delete Requested
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      border: 2,
                      borderColor: "divider",
                      padding: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Grid
                      // size={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Typography>Delete request sent</Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Typography>Being processed</Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size="grow"
                    >
                      <Box sx={{ position: "relative" }}>
                        <Button
                          disabled={isDeleteLoading}
                          onClick={() => cancelAccountActivity("DELETE")}
                        >
                          Cancel delete request
                        </Button>
                        {isDeleteLoading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Button variant="outlined" onClick={handleOpenDeleteModal}>
                  Delete account
                </Button>
              )}
            </Item>
          </Stack>
          <MyModal
            open={openTransferModal}
            handleClose={handleCloseTransferModal}
          >
            <TransferAccount
              setMyHospital={setMyHospital}
              handleClose={handleCloseTransferModal}
            />
          </MyModal>
          <MyModal
            open={openSuspendModal}
            handleClose={handleCloseSuspendModal}
          >
            <SuspendAccount
              setMyHospital={setMyHospital}
              handleClose={handleCloseSuspendModal}
            />
          </MyModal>
          <MyModal open={openDeleteModal} handleClose={handleCloseDeleteModal}>
            <DeleteAccount
              setMyHospital={setMyHospital}
              handleClose={handleCloseDeleteModal}
            />
          </MyModal>
        </Box>
      )}
    </>
  );
}
