import Container from '@mui/material/Container';
import AccessControlToolbar from '../../components/access-control/AccessControlToolbar';
import AccessControlTable from '../../components/access-control/AccessControlTable';
import PageHeading from '../../components/reusables/PageHeading';

import AccessControlAction from '../../components/access-control/AccessControlAction';

function AccessControlPage() {
  return (
    <Container maxWidth="lg">
      <PageHeading heading="Users" action={<AccessControlAction />} />
      <AccessControlToolbar />
      <AccessControlTable />
    </Container>
  );
}

export default AccessControlPage;
