import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import api from "../../utilities/api";
import { getDepartmentsData, getDefaultDepartments } from "./utilDepartments";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";

// for autocomplete

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledForm = styled("form")({
  //   display: 'flex',
  //   alignItems: 'center',
  width: "100%",
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const initialValues = {
  departments: [],
};

const validation = yup.object().shape({
  departments: yup.array().min(1, "Choose at least one department").compact(),
});

function DepartmentsForm() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const hospital = useSelector((state) => state.auth.hospital);
  const { defaultDepartments } = useSelector((state) => state.departments);
  const { departmentsList, isLoading } = useSelector(
    (state) => state.departments
  );

  const unSelectedDepartments = defaultDepartments?.filter(
    (unSelectedOption) =>
      !departmentsList?.some(
        (selectionOption) => unSelectedOption?.name === selectionOption?.name
      )
  );

  useEffect(() => {
    getDefaultDepartments();
  }, [hospital]);

  const onSubmit = async (values, actions) => {
    const formData = {
      hospital: hospital._id,
      departments: values.departments,
    };
    try {
      const url = `/hospitals/${hospital._id}/departments`;
      const response = await api.post(url, formData);
      if (response) {
        getDepartmentsData(hospital._id);
        actions.resetForm({
          departments: [],
        });
        actions.setStatus({ success: true });
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError("departments", err.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  return (
    <>
      <Card sx={{ marginTop: 2 }}>
        <CardHeader
          title="Select Departments"
          subheader="Select all departments availabe in the hospital"
          sx={{ py: 3, px: 2 }}
        />
        <Box sx={{ marginBottom: 2, px: 2 }}>
          <StyledForm
            className="departments-form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Grid container spacing={{ xs: 1 }}>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  multiple
                  value={formik.values.departments}
                  id="departments"
                  name="departments"
                  // options={defaultDepartments}
                  options={unSelectedDepartments || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("departments", newValue);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  //   style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Departments"
                      placeholder="Search Department"
                      error={
                        formik.touched.departments &&
                        Boolean(formik.errors.departments)
                      }
                      helperText={
                        formik.touched.departments && formik.errors.departments
                      }
                    />
                  )}
                />
                {/** 
                <TextField
                  // sx={{ marginRight: 1 }}
                  size='small'
                  fullWidth
                  id='departments'
                  name='departments'
                  label='Departments'
                  autoComplete='off'
                  autoCapitalize='off'
                  autoCorrect='off'
                  value={formik.values.departments}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.departments &&
                    Boolean(formik.errors.departments)
                  }
                  helperText={
                    formik.touched.departments && formik.errors.departments
                  }
                />*/}
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Button
                    size="large"
                    sx={{ float: "right" }}
                    type="submit"
                    startIcon={<AddIcon />}
                    color="primary"
                    variant="contained"
                    disabled={formik.isSubmitting}
                  >
                    Add
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </StyledForm>
        </Box>
      </Card>
    </>
  );
}

export default DepartmentsForm;
