import { useEffect } from "react";
import Box from "@mui/material/Box";
import Hero from "../../components/home/Hero";
import Features from "../../components/home/Features";
import WhatAreYouWaiting from "../../components/home/WhatAreYouWaiting";
import AllFeatures from "../../components/home/AllFeatures";

function HomePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <Box>
        <Hero />
      </Box>
      <Box>
        <Features />
      </Box>
      <Box
        sx={{
          marginBottom: 7,
        }}
      >
        <WhatAreYouWaiting />
      </Box>
      <Box>
        <AllFeatures />
      </Box>
    </Box>
  );
}

export default HomePage;
