import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/system/Box";
import CardItem from "./CardItem";
import MyDialog from "../dialog/MyDialog";
import { getPrice } from "./util";

export default function SubscriptionCard({ plan, activePlan, features }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setOpen(false);
  };

  return (
    <Card
      sx={{
        minWidth: 160,
        textAlign: "left",
        padding: 2,
      }}
    >
      <CardActions sx={{ my: 2 }}>
        <Button
          onClick={() => {
            if (plan?.title === "Enterprise") {
              openDialog();
            } else {
              navigate("/onboarding");
            }
          }}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {plan?.title === "Enterprise"
            ? "Contact to start this plan"
            : "Signup for this plan"}
        </Button>
      </CardActions>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getPrice(plan?.currency, plan?.price, plan?.discountPercentage)}
          </Box>
          <Typography mb={1} variant="h3">
            {plan?.title}
          </Typography>

          <Typography variant="body2">{plan?.subtitle} </Typography>
        </Box>
        {features?.map((feature) => (
          <CardItem
            key={feature.feature}
            feature={feature}
            planTitle={plan?.title}
          />
        ))}
      </CardContent>
      <MyDialog
        handleYes={handleYes}
        open={open}
        closeDialog={closeDialog}
        text="Contact Daily Doc sales by email dailydocllc@gmail.com or WhatsApp on +1 6124225444"
      />
    </Card>
  );
}
