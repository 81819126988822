import HospitalTable from "../../components/add-hosppital/HospitalTable";

const ProfileHospitalsPage = () => {
  return (
    <>
      <HospitalTable />
    </>
  );
};

export default ProfileHospitalsPage;
