import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import EmailIcon from "@mui/icons-material/Email";
import api from "../../utilities/api";

import { styled } from "@mui/system";
import { showSnackbarAction } from "../../redux/alert/alertAction";

const StyledForm = styled("form")({
  //   display: 'flex',
  //   alignItems: 'center',
  width: "100%",
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const initialValues = {
  title: "",
  content: "",
};

const validation = yup.object({
  title: yup.string().required("Required"),
  content: yup.string().required("Required"),
});

export default function FeedbackForm() {
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      feedBackDetails: {
        content: values.content,
        title: values.title,
      },
    };
    try {
      const url = `/hospitals/${hospital?._id}/dashboard/feedbacks`;
      const response = await api.post(url, formData);
      if (response) {
        dispatch(showSnackbarAction(response.data.message, "success"));
        actions.resetForm({ values: initialValues });
        actions.setStatus({ success: true });
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError("content", err.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  return (
    <>
      <Card sx={{ marginTop: 2, padding: 4 }}>
        <CardHeader
          title="Thank you for helping us improve Daily Doc. Your feedback is valuable."
          subheader="Please submit feedback using following form."
          sx={{ py: 3, px: 3 }}
        />
        <Box sx={{ marginBottom: 2, px: 3 }}>
          <StyledForm
            className="ward-form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              id="title"
              name="title"
              label="Title*"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              sx={{ marginBottom: 3 }}
              multiline
              rows={5}
              fullWidth
              id="content"
              name="content"
              label="Feedback content*"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              value={formik.values.content}
              onChange={formik.handleChange}
              error={formik.touched.content && Boolean(formik.errors.content)}
              helperText={formik.touched.content && formik.errors.content}
            />
            <Box>
              <Button
                type="submit"
                startIcon={<EmailIcon />}
                color="primary"
                variant="contained"
                disabled={formik.isSubmitting}
              >
                Send
              </Button>
            </Box>
          </StyledForm>
        </Box>
      </Card>
    </>
  );
}
